const branding = {
  ENERGIC: 'energic',
  VOLTIE: 'voltie',
};

const ocppMessageType = {
  RMT_START_TRANSACTION: 'rmt-start-transaction',
  RMT_STOP_TRANSACTION: 'rmt-stop-transaction',
  EC_METER_VALUE: 'ec-meter-value',
  EC_NO_OPERATION: 'ec-no-operation',
  WC_UPDATE_TRANSACTION_ID: 'wc-update-transaction-id',
  RESET_CP: 'reset-cp',
  ADD_REMOVE_RFID_CARD: 'add-remove-rfid-card',
  CHANGE_CONFIGURATION: 'change-configuration',
  CHANGE_AVAILABILITY: 'change-availability',
  CLEAR_CACHE: 'clear-cache',
  GET_CONFIGURATION: 'get-configuration',
  UNLOCK_CONNECTOR: 'unlock-connector',
  SUPER_ADMIN_COMMANDS: 'super-admin-commands',
  GET_COMPOSITE_SCHEDULE: 'get-composite-schedule',
  RESERVE_NOW: 'reserve-now',
  CANCEL_RESERVATION: 'cancel-reservation',
  SEND_LOCAL_LIST: 'send-local-list',
  GET_DIAGNOSTICS: 'get-diagnostics',
  GET_LOCAL_LIST_VERSION: 'get-local-list-version',
  UPDATE_FIRMWARE: 'update-firmware',
  TRIGGER_MESSAGE: 'trigger-message',
};

export { branding, ocppMessageType };
