import { useEffect } from 'react';
// import SCSS file
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { GoogleMapsProvider } from './contexts/google-map-context/GoogleMapContent';
import { ThemeProvider } from './contexts/theme-context/ThemeProvider';
import { WebSocketProvider } from './contexts/WebSocket/WebSocketProvider';
import Router from './pages/router';
import { store } from './redux/store';
import MetaManager from './shared-components/MetaManager/MetaManager';

/**
 * Main body page
 */
function App() {
  // const theme: string = 'light';
  useEffect(() => {
    // Get the favicon based on the environment variable
    const favicon =
      process.env.REACT_APP_BRANDING === 'voltie' ? '/favicon_voltie.ico' : '/favicon.ico';

    // Find the existing favicon link tag
    const link = document.querySelector("link[rel*='icon']");

    if (link instanceof HTMLLinkElement) {
      link.href = favicon; // Update the href attribute
    } else {
      // If no favicon link exists, create one
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, []); // Runs once on component mount

  return (
    <GoogleMapsProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''}>
      <WebSocketProvider>
        <ThemeProvider>
          <Provider store={store}>
            <div className="app">
              <MetaManager />
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </div>
          </Provider>
        </ThemeProvider>
      </WebSocketProvider>
    </GoogleMapsProvider>
  );
}

export default App;
