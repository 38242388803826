import config from '../../config/config';

import postRequest from './base/post';

// interface QueryParameters {
//   limit?: number;
//   [key: string]: any;
// }

export default {
  /**
   * Create the user api call function
   * @param data
   * @returns
   */
  anyCommand: (data: any): Promise<any> => {
    const parameters = { ...data };
    return postRequest(`${config.ocppApiUrl}api/ocpp16/v1/ocpp-events/any-command`, {
      ...parameters,
    });
  },
};
