import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import CancelReservation from '../../ocpp-message-pages/cancel-reservation/CancelReservation';
import ChangeConfiguration from '../../ocpp-message-pages/change-configuration/ChangeConfiguration';
import ClearCache from '../../ocpp-message-pages/clear-cache/ClearCache';
import GetConfiguration from '../../ocpp-message-pages/get-configuration/GetConfiguration';
import GetDiagnostics from '../../ocpp-message-pages/get-diagnostics/GetDiagnostics';
import GetLocalVersionList from '../../ocpp-message-pages/get-local-list-version/GetLocalListVersion';
import OcppConsole from '../../ocpp-message-pages/ocpp-console/OcppConsole';
import RemoteStartTransaction from '../../ocpp-message-pages/remote-start-charging/RemoteStartTransaction';
import RemoteStopTransaction from '../../ocpp-message-pages/remote-stop-charging/RemoteStopTransaction';
import ReserveNow from '../../ocpp-message-pages/reserve-now/ReserveNow';
import Reset from '../../ocpp-message-pages/reset/Reset';
import TriggerMessage from '../../ocpp-message-pages/trigger-message/TriggerMessage';
import UnlockConnector from '../../ocpp-message-pages/unlock-connector/UnlockConnector';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import Header from '../../shared-components/header/Header';
import OCPPMessageSidebar from '../../shared-components/ocpp-message/sidebar/Sidebar';
import Sidebar from '../../shared-components/sidebar/Sidebar';
import ToastMessage from '../../shared-components/toast-message/ToastMessage';
import AccountManagement from '../account-management';
import ChargingStationManagement from '../charging-station';
import Dashboard from '../dashboard';
import DeviceQrManagement from '../device-qr-management';
import DynamicPricing from '../dynamic-pricing';
import EndConsumerManagement from '../end-consumer-management';
import Login from '../login';
import ForgetPassword from '../login/components/forgot-password';
import NewPasswordChange from '../login/components/new-password-change';
import OTPVerification from '../login/components/otp-verification';
import PaymentManagement from '../payments';
import ReportManagement from '../reports';
import RevenueManagement from '../revenue-management';
import UserManagement from '../user-management';

import AuthGuard from './components/AuthGuard';

/**
 * Wrapper Route function component
 */
function WrapperRoute() {
  return (
    <div className="ev-layout-wrap">
      <div className="ev-sidebar">
        <Sidebar />
      </div>
      <div className="ev-content-wrap">
        <Header />
        <div className="ev-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

/**
 * Function to handle OCPP message commands
 * @returns
 */
function OcppWrapperRoute() {
  return (
    <div className="ev-layout-wrap">
      <div className="ev-sidebar">
        <OCPPMessageSidebar />
      </div>
      <div className="ocpp-message-content-wrap">
        <div className="ocpp-message-conetnt">
          <Outlet />
        </div>
        <div className="ocpp-console">
          <OcppConsole />
        </div>
      </div>
    </div>
  );
}

/**
 * Router page function component
 */
function Router() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notificationMessage, notificationType, notificationStatus, notificationCode } =
    useAppSelector((state) => state.notification);
  const routes = [
    {
      path: 'dashboard',
      children: [
        {
          id: 1,
          path: '',
          element: <Dashboard />,
          permission: ['dashboard'],
        },
      ],
    },
    {
      path: 'account',
      children: [
        {
          id: 2,
          path: '',
          element: <AccountManagement />,
          permission: ['accounts_management'],
        },
      ],
    },
    {
      path: 'charging-station',
      children: [
        {
          id: 3,
          path: ':currentPage',
          element: <ChargingStationManagement />,
          permission: ['charging_station'],
        },
      ],
    },
    {
      path: 'reports',
      children: [
        {
          id: 4,
          path: '',
          element: <ReportManagement />,
          permission: ['reporting_tools'],
        },
      ],
    },
    {
      path: 'user-management',
      children: [
        {
          id: 5,
          path: ':currentPage',
          element: <UserManagement />,
          permission: ['user_management'],
        },
      ],
    },
    {
      path: 'end-consumer',
      children: [
        {
          id: 6,
          path: '',
          element: <EndConsumerManagement />,
          permission: ['end_consumer_management'],
        },
      ],
    },
    {
      path: 'payments',
      children: [
        {
          id: 7,
          path: '',
          element: <PaymentManagement />,
          permission: ['payments'],
        },
      ],
    },
    {
      path: 'revenue/:page',
      children: [
        {
          id: 8,
          path: '',
          element: <RevenueManagement />,
          permission: ['wallet_revenue_sharing'],
        },
      ],
    },
    {
      path: 'dynamic-pricing/:currentPage',
      children: [
        {
          id: 8,
          path: '',
          element: <DynamicPricing />,
          permission: ['dynamic_pricing'],
        },
      ],
    },
    {
      path: 'device-qr-management',
      children: [
        {
          id: 8,
          path: '',
          element: <DeviceQrManagement />,
          permission: [],
        },
      ],
    },
  ];

  useEffect(() => {
    if (notificationCode === 401) {
      navigate('/login');
    }
  }, [notificationCode]);
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/otp-verification" element={<OTPVerification />} />
        <Route path="/new-password" element={<NewPasswordChange />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/ev-admin" element={<WrapperRoute />}>
          {routes.map((route) => (
            <Route path={route.path}>
              {route.children.map((subRoute) => (
                <Route
                  key={subRoute?.id}
                  path={subRoute?.path}
                  element={
                    <AuthGuard permission={subRoute?.permission} Component={subRoute?.element} />
                  }
                />
              ))}
            </Route>
          ))}
        </Route>
        {/* Route to manage ocpp messages */}
        <Route path="/ocpp-message" element={<OcppWrapperRoute />}>
          <Route path="change-configuration" element={<ChangeConfiguration />} />
          <Route path="get-configuration" element={<GetConfiguration />} />
          <Route path="clear-cache" element={<ClearCache />} />
          <Route path="get-diagnostics" element={<GetDiagnostics />} />
          <Route path="remote-start-transaction" element={<RemoteStartTransaction />} />
          <Route path="remote-stop-transaction" element={<RemoteStopTransaction />} />
          <Route path="unlock-connector" element={<UnlockConnector />} />
          <Route path="reserve-now" element={<ReserveNow />} />
          <Route path="cancel-reservation" element={<CancelReservation />} />
          <Route path="reset" element={<Reset />} />
          <Route path="get-local-list-version" element={<GetLocalVersionList />} />
          <Route path="trigger-message" element={<TriggerMessage />} />
        </Route>
      </Routes>
      <ToastMessage
        open={notificationStatus}
        type={notificationType}
        message={notificationMessage || 'Something went wrong!'}
        onClose={() =>
          dispatch(
            setNotificationMessage({
              status: false,
              message: '',
              type: 'error',
              code: 0,
            }),
          )
        }
        onCloseTimer={3000}
      />
    </>
  );
}

export default Router;
