// WebSocketService.ts
import { EventEmitter } from 'events';

import { pushReceivedMessage } from '../redux/slices/OcppMessageSlice';
import { store } from '../redux/store';

class WebSocketService {
  private socket: WebSocket | null = null;

  private eventEmitter = new EventEmitter();

  /**
   * Function to connect socket
   * @param url
   */
  connect(url: string) {
    /**
     * Create new socket connection
     */
    this.socket = new WebSocket(url);

    /**
     * Establish a connection
     */
    this.socket.onopen = () => {
      console.log('WebSocket connected');
    };

    /**
     * Emitter for web socket messages
     */
    this.socket.onmessage = (event) => {
      const receivedData: any = JSON.parse(event.data);
      console.log(receivedData, 'eventData');
      store.dispatch(
        pushReceivedMessage({
          type: receivedData?.messageType || '',
          value: JSON.stringify(receivedData?.message || {}),
        }),
      );
      this.eventEmitter.emit('message', receivedData);
    };

    /**
     * Console websocket error
     */
    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    /**
     * Console when web socket connection closes
     */
    this.socket.onclose = () => {
      console.log('WebSocket disconnected');
    };
  }

  /**
   * Function to disconnect socket
   */
  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  /**
   * Function to send messages to socket
   * @param message
   */
  sendMessage(message: object) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not connected');
    }
  }

  /**
   * Function to establish listener from a component
   * @param callback
   */
  subscribe(callback: (data: any) => void) {
    this.eventEmitter.on('message', callback);
  }

  /**
   * Function to disable/off connection to a event listener
   * @param callback
   */
  unsubscribe(callback: (data: any) => void) {
    this.eventEmitter.off('message', callback);
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;
