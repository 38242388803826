/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  ChargingPointAPIResponse,
  ChargingPointInitialProperties,
  ChargingPointInterFace,
} from '../../interface/charging-point.interface';
import chargingPoint from '../../utils/api/charging-point';

// a thunk to fetch users
export const fetchAllCharingPoints = createAsyncThunk<ChargingPointAPIResponse>(
  'charging-point/fetchAllCharingPoints',
  async (_, { dispatch }) => {
    try {
      const response = await chargingPoint.fetchChargingPoints();
      return response.data;
    } catch (error: any) {
      dispatch({
        type: 'notification/setNotificationMessage',
        payload: {
          type: 'error',
          CONNREFUSED: error?.response?.data?.code || 200,
          message: error?.response?.data?.message || 'Something went wrong!',
          status: true,
        },
      });
      throw error;
    }
  },
);

// Initial state
const initialState: ChargingPointInitialProperties = {
  chargingPoints: [],
  loading: false,
};

// Create the slice
export const chargingPointSlice = createSlice({
  name: 'charging-point',
  initialState,
  reducers: {},
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<ChargingPointInitialProperties>) => {
    builder
      .addCase(fetchAllCharingPoints.pending, (state: ChargingPointInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllCharingPoints.fulfilled,
        (
          state: ChargingPointInitialProperties,
          action: PayloadAction<ChargingPointAPIResponse>,
        ) => {
          state.loading = false;

          const allChargingPoints = [
            ...(action.payload.chargingPoints || []),
            ...(action.payload.personalChargingPoints || []),
          ];

          state.chargingPoints = allChargingPoints.map(
            (eachChargingPoint: ChargingPointInterFace) => ({
              label: eachChargingPoint.chargingPointSn,
              value: eachChargingPoint.chargingPointSn,
            }),
          );
        },
      )
      .addCase(fetchAllCharingPoints.rejected, (state: ChargingPointInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
// export const { } = chargingPointSlice.actions;
// Export the reducer
export default chargingPointSlice.reducer;
