import React, { useEffect, useState } from 'react';

import { ocppMessageType } from '../../config/enum';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAllCharingPoints } from '../../redux/slices/ChargingPointSlice';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomInput from '../../shared-components/custom-input/CustomInput';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import MultiSelectDropdown from '../../shared-components/multi-select-field/MultiSelectField';
import PageTitle from '../../shared-components/page-title/PageTitle';
import SuccessNotification from '../../shared-components/success-notification/SuccessNotificaion';
import ocppEvents from '../../utils/api/ocpp-events';

interface ClearCacheProperties {
  devices: string[];
  resetType: string;
}

interface ModalBoxDetail {
  title: String;
  content: JSX.Element;
  notificationModal: boolean;
  rightCorner: string;
}

/**
 * OCPP message command reset cp
 */
function Reset() {
  const dispatch = useAppDispatch();
  const { chargingPoints } = useAppSelector((state) => state.chargingPoint);

  const [configuration, setConfiguration] = useState<ClearCacheProperties>({
    devices: [],
    resetType: '',
  });

  const [modalBoxStatus, setModalBoxStatus] = useState<'open' | 'close'>('close');
  const [modalBoxDetail, setModalBoxDetail] = useState<ModalBoxDetail>({
    title: '',
    content: <div />,
    rightCorner: '',
    notificationModal: false,
  });

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setConfiguration((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };

  /**
   * handle select all
   */
  const handleSelectAll = () => {
    setConfiguration((previousValue) => ({
      ...previousValue,
      devices: chargingPoints.map((device) => device.value),
    }));
  };

  /**
   * handle select none
   */
  const handleSelectNone = () => {
    setConfiguration((previousValue) => ({
      ...previousValue,
      devices: [],
    }));
  };

  /**
   * Function to reset configuration input
   */
  const handleResetInput = () => {
    setModalBoxStatus('close');
    setConfiguration({
      devices: [],
      resetType: '',
    });
  };

  /**
   * Function to submit configuration
   */
  const handleSubmit = async () => {
    if (configuration.devices.length > 0) {
      ocppEvents
        .anyCommand({
          type: ocppMessageType.RESET_CP,
          body: {
            resetType: configuration.resetType,
          },
          chargingPointSns: configuration.devices,
        })
        .then(() => {
          setModalBoxStatus('open');
          setModalBoxDetail({
            title: '',
            rightCorner: '',
            content: (
              <SuccessNotification
                title="Rest Device Requested"
                description=""
                notificationCloseTimer={3000}
                onNotificationClose={handleResetInput}
              />
            ),
            notificationModal: true,
          });
        })
        .catch((error) =>
          dispatch(
            setNotificationMessage({
              status: true,
              message: error?.response?.data?.message || 'Something went wrong!',
              type: 'error',
              code: 0,
            }),
          ),
        );
    } else {
      dispatch(
        setNotificationMessage({
          status: true,
          message: 'Device required!',
          type: 'error',
          code: 0,
        }),
      );
    }
  };

  useEffect(() => {
    if (chargingPoints.length === 0) {
      dispatch(fetchAllCharingPoints());
    }
  }, []);

  return (
    <div className="change-configuration-wrap">
      <ModalBox
        open={modalBoxStatus === 'open'}
        title={modalBoxDetail.title}
        content={modalBoxDetail.content}
        closeModal={() => setModalBoxStatus('close')}
        rightCorner={modalBoxDetail.rightCorner}
        notification={modalBoxDetail.notificationModal}
        header={!modalBoxDetail.notificationModal}
      />
      <PageTitle title="Charge Points with OCPP v1.6 (Reset Charging Point)" />
      <div className="change-configuration-container">
        <div className="configuration-multi-select-wrap">
          <MultiSelectDropdown
            placeholderFilled="Devices"
            title="Select Device"
            options={chargingPoints}
            name="devices"
            placeholder="Select Device"
            search
            selectedValues={configuration.devices}
            onSelectionChange={handleInputChange}
          />
          <div className="multiple-button">
            <MyButton label="Select All" buttonType="submit" onClickFunc={handleSelectAll} />
            <MyButton label="Select None" buttonType="submit" onClickFunc={handleSelectNone} />
          </div>
        </div>
        <div className="side-heading">
          <p>Parameters</p>
        </div>
        <div className="change-configuration-parameters">
          <div className="single-row">
            <CustomInput
              name="resetType"
              inputLabel="Reset type"
              type="text"
              placeholder="Enter reset type"
              value={configuration.resetType}
              onChangeValue={handleInputChange}
            />
          </div>
        </div>
        <div className="form-buttons">
          <MyButton label="Cancel" buttonType="back" onClickFunc={handleResetInput} />
          <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
        </div>
      </div>
    </div>
  );
}

export default Reset;
