import { ReactComponent as EnergicCaffeeActive } from '../assets/icons/amenities/caffee-color-icon.svg';
import { ReactComponent as EnergicCarWashActive } from '../assets/icons/amenities/car-wash-color-icon.svg';
import { ReactComponent as EnergicGasActive } from '../assets/icons/amenities/gas-item-color-icon.svg';
import { ReactComponent as EnergicLoungeActive } from '../assets/icons/amenities/lounge-active.svg';
import { ReactComponent as EnergicRelasingActive } from '../assets/icons/amenities/relasing-color-icon.svg';
import { ReactComponent as EnergicRestaurantActive } from '../assets/icons/amenities/restaurant-active.svg';
import { ReactComponent as EnergicRestroomActive } from '../assets/icons/amenities/restroom-color-icon.svg';
import { ReactComponent as EnergicSettingActive } from '../assets/icons/amenities/setting-color-icon.svg';
import { ReactComponent as EnergicShoppingActive } from '../assets/icons/amenities/shopping-color-icon.svg';
import { ReactComponent as VoltieCaffeeActive } from '../assets/icons/amenities/voltie/caffee-color-icon.svg';
import { ReactComponent as VoltieCarWashActive } from '../assets/icons/amenities/voltie/car-wash-color-icon.svg';
import { ReactComponent as VoltieGasActive } from '../assets/icons/amenities/voltie/gas-item-color-icon.svg';
import { ReactComponent as VoltieLoungeActive } from '../assets/icons/amenities/voltie/lounge-active.svg';
import { ReactComponent as VoltieRelasingActive } from '../assets/icons/amenities/voltie/relasing-color-icon.svg';
import { ReactComponent as VoltieRestaurantActive } from '../assets/icons/amenities/voltie/restaurant-active.svg';
import { ReactComponent as VoltieRestroomActive } from '../assets/icons/amenities/voltie/restroom-color-icon.svg';
import { ReactComponent as VoltieSettingActive } from '../assets/icons/amenities/voltie/setting-color-icon.svg';
import { ReactComponent as VoltieShoppingActive } from '../assets/icons/amenities/voltie/shopping-color-icon.svg';
import { ReactComponent as VoltieWifiActive } from '../assets/icons/amenities/voltie/wifi-color-icon.svg';
import { ReactComponent as EnergicWifiActive } from '../assets/icons/amenities/wifi-color-icon.svg';
import { ReactComponent as EnergicActiveClockIcon } from '../assets/icons/dashboard/clock-icon.svg';
import { ReactComponent as EnergicActiveConnectorIcon } from '../assets/icons/dashboard/connector-icon.svg';
import { ReactComponent as EnergicActiveDeviceIcon } from '../assets/icons/dashboard/device-icon.svg';
import { ReactComponent as EnergicCardEnergyIcon } from '../assets/icons/dashboard/map-energy-icon.svg';
import { ReactComponent as EnergicCardRevenueIcon } from '../assets/icons/dashboard/map-revenue-icon.svg';
import { ReactComponent as EnergicCardMultiUserIcon } from '../assets/icons/dashboard/map-user-icon.svg';
import energicStatinLocator from '../assets/icons/dashboard/station-locator.svg';
import { ReactComponent as EnergicFacility } from '../assets/icons/facility-color-icon.svg';
import { ReactComponent as EnergicLoginBanner } from '../assets/icons/login-banner.svg';
import { ReactComponent as EnergicMultiUser } from '../assets/icons/multi-users.svg';
import { ReactComponent as EnergicCheckBoxActiveIcon } from '../assets/icons/radio-active-icon.svg';
import { ReactComponent as EnergicCheckBoxIcon } from '../assets/icons/radio-button-icon.svg';
import { ReactComponent as VoltieDashboardClockIcon } from '../assets/icons/voltie/dashboard/clock-icon.svg';
import { ReactComponent as VoltieDashboardConnectorIcon } from '../assets/icons/voltie/dashboard/connector-icon.svg';
import { ReactComponent as VoltieDashboardDeviceIcon } from '../assets/icons/voltie/dashboard/device-icon.svg';
import { ReactComponent as VoltieCardEnergyIcon } from '../assets/icons/voltie/dashboard/map-energy-icon.svg';
import { ReactComponent as VoltieCardRevenueIcon } from '../assets/icons/voltie/dashboard/map-revenue-icon.svg';
import { ReactComponent as VoltieCardMultiUserIcon } from '../assets/icons/voltie/dashboard/map-user-icon.svg';
import { ReactComponent as VoltieFacility } from '../assets/icons/voltie/facility-color-icon.svg';
import { ReactComponent as VoltieLoginBanner } from '../assets/icons/voltie/login-banner.svg';
import { ReactComponent as VoltieMultiUser } from '../assets/icons/voltie/multi-users.svg';
import { ReactComponent as VoltieCheckBoxActiveIcon } from '../assets/icons/voltie/radio-active-icon.svg';
import { ReactComponent as VoltieCheckBoxIcon } from '../assets/icons/voltie/radio-button-icon.svg';
import voltieStatinLocator from '../assets/icons/voltie/station-locator.svg';
import energicChargingDevice from '../assets/images/dashboard/energic-device.png';
import voltieChargingDevice from '../assets/images/dashboard/volti-device.png';
import energicLogo from '../assets/images/logo.png';
import voltieLogo from '../assets/images/voltie-logo.png';

import { branding } from './enum'; // Enum for brand keys

export const appCurrentBranding = process.env.REACT_APP_BRANDING || 'energic';

// Define image and icon mappings for each brand
const brandMapping = {
  [branding.ENERGIC]: {
    images: {
      logo: energicLogo,
      chargingDevice: energicChargingDevice,
    },
    icons: {
      LoginBanner: EnergicLoginBanner,
      CafeActive: EnergicCaffeeActive,
      CarWashActive: EnergicCarWashActive,
      GasActive: EnergicGasActive,
      LoungeActive: EnergicLoungeActive,
      RelaxingActive: EnergicRelasingActive,
      RestaurantActive: EnergicRestaurantActive,
      RestroomActive: EnergicRestroomActive,
      SettingActive: EnergicSettingActive,
      ShoppingActive: EnergicShoppingActive,
      WifiActive: EnergicWifiActive,
      MultiUser: EnergicMultiUser,
      Facility: EnergicFacility,
      stationLocator: energicStatinLocator,
      dashboardClock: EnergicActiveClockIcon,
      dashboardGas: EnergicActiveDeviceIcon,
      dashboardCharge: EnergicActiveConnectorIcon,
      dashboardElectricity: EnergicCardEnergyIcon,
      dashboardRevenue: EnergicCardRevenueIcon,
      dashboardMultiUser: EnergicCardMultiUserIcon,
      cardEnergyIcon: EnergicCardEnergyIcon,
      cardRevenueIcon: EnergicCardRevenueIcon,
      cardMultiUserIcon: EnergicCardMultiUserIcon,
      CheckBoxActiveIcon: EnergicCheckBoxActiveIcon,
      CheckBoxIcon: EnergicCheckBoxIcon,
    },
    meta: {
      latLong: { lat: 24.7113281, lng: 46.6733308 },
      title: 'Energic - Super Admin',
      description:
        'Take control of your EV charging stations with Energic Control. This powerful web app offers seamless management, dynamic pricing, real-time monitoring, and smart scheduling to maximize efficiency and performance. Perfect for EV facility owners looking to streamline operations.',
    },
  },
  [branding.VOLTIE]: {
    images: {
      logo: voltieLogo,
      logoDarkTheme: voltieLogo,
      chargingDevice: voltieChargingDevice,
    },
    icons: {
      LoginBanner: VoltieLoginBanner,
      CafeActive: VoltieCaffeeActive,
      CarWashActive: VoltieCarWashActive,
      GasActive: VoltieGasActive,
      LoungeActive: VoltieLoungeActive,
      RelaxingActive: VoltieRelasingActive,
      RestaurantActive: VoltieRestaurantActive,
      RestroomActive: VoltieRestroomActive,
      SettingActive: VoltieSettingActive,
      ShoppingActive: VoltieShoppingActive,
      WifiActive: VoltieWifiActive,
      MultiUser: VoltieMultiUser,
      Facility: VoltieFacility,
      stationLocator: voltieStatinLocator,
      dashboardClock: VoltieDashboardClockIcon,
      dashboardGas: VoltieDashboardDeviceIcon,
      dashboardCharge: VoltieDashboardConnectorIcon,
      dashboardElectricity: VoltieCardEnergyIcon,
      dashboardRevenue: VoltieCardRevenueIcon,
      dashboardMultiUser: VoltieCardMultiUserIcon,
      cardEnergyIcon: VoltieCardEnergyIcon,
      cardRevenueIcon: VoltieCardRevenueIcon,
      cardMultiUserIcon: VoltieCardMultiUserIcon,
      CheckBoxActiveIcon: VoltieCheckBoxActiveIcon,
      CheckBoxIcon: VoltieCheckBoxIcon,
    },
    meta: {
      latLong: { lat: 41.8781, lng: -87.6298 },
      title: 'Voltie Power Up – Super Admin',
      description:
        'Take control of your EV charging stations with Voltie Power Up. This powerful web app offers seamless management, dynamic pricing, real-time monitoring, and smart scheduling to maximize efficiency and performance. Perfect for EV facility owners looking to streamline operations.',
    },
  },
};

// Fallback configuration (optional)
const defaultBrand = brandMapping[branding.ENERGIC];

// Determine the current brand's configuration
const currentBrand = process.env.REACT_APP_BRANDING
  ? brandMapping[process.env.REACT_APP_BRANDING]
  : defaultBrand;

// Export the brand-specific configurations
export const { images: brandImage, icons: brandIcons, meta: metaValues } = currentBrand;
