const config = {
  accountApiUrl: `${process.env.REACT_APP_API_URL}account/`,
  authApiUrl: `${process.env.REACT_APP_API_URL}auth/`,
  commonApiUrl: `${process.env.REACT_APP_API_URL}common/`,
  ocppApiUrl: `${process.env.REACT_APP_API_URL}ocpp-service/`,
  reportApiUrl: `${process.env.REACT_APP_API_URL}reporting/`,
  notificationApiUrl: `${process.env.REACT_APP_API_URL}notification/`,
  transactionApiUrl: `${process.env.REACT_APP_API_URL}transactions/`,
  websocketUrl: process.env.REACT_APP_SOCKET_SERVER_URL || '',
};

export default config;
