import React, { useState, useRef, useEffect, useCallback } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/up-arrow.svg';

interface Option {
  label: string;
  value: string;
}

interface MultiSelectDropdownProperties {
  name: string;
  options: Option[];
  selectedValues: string[];
  onSelectionChange: (name: string, values: string[]) => void;
  title?: string;
  placeholderFilled: string;
  placeholder?: string;
  icon?: JSX.Element;
  search?: boolean;
  multiSelectDisable?: boolean;
}
/**
 * MultiSelectDropdown component provides a dropdown menu allowing users to select multiple options.
 *
 * This component is customizable and can be used in forms where multiple selections are required,
 * such as filtering items by categories or tags.
 * @param {object} props - The component props.
 * @param {string[]} props.options - The list of options available for selection.
 * @param {string[]} props.selectedValues - The values currently selected by the user.
 * @param {Function} props.onChange - Callback function triggered when the selected values change.
 * @returns {JSX.Element} The rendered multi-select dropdown component.
 */
function MultiSelectDropdown({
  options,
  selectedValues,
  name,
  onSelectionChange,
  title = 'Select Role',
  placeholderFilled,
  placeholder = 'Select Option',
  icon,
  multiSelectDisable,
  search,
}: MultiSelectDropdownProperties) {
  const reference = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [, setSelectValue] = useState<string>('');
  /**
   * Handles the change event for a checkbox in the multi-select dropdown.
   * It updates the selected values based on whether the checkbox is checked or unchecked.
   * @param {string} value - The value of the checkbox that was changed.
   */
  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((option) => option !== value)
      : [...selectedValues, value];
    onSelectionChange(name, newSelectedValues);
    setSelectValue(newSelectedValues.toString());
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);
  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="multi-select-dropdown">
      <div className="multi-select-header">{title && <span>{title}</span>}</div>
      <div className={`multi-select-dropdown-list ${isOpen ? 'open' : ''}`}>
        <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)} role="presentation">
          <div className="left-section">
            {icon}
            <span>{selectedValues.length ? `${placeholderFilled} Selected` : placeholder}</span>
          </div>
          <span className={`dropdown-arrow `}>{isOpen ? <DownArrowIcon /> : <ArrowIcon />}</span>
        </div>
        {isOpen && (
          <div
            className={`dropdown-content ${isOpen ? 'open' : ''}`}
            ref={reference}
            style={{ borderTop: search ? 'none' : '' }}>
            {search && (
              <div className="multi-select-search">
                <input
                  type="text"
                  className="dropdown-search"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
                <div className="search-box">
                  <span>
                    <Search />
                  </span>
                </div>
              </div>
            )}
            {isOpen && (
              <div className={isOpen ? 'dropdown-list' : 'opened'}>
                <div className="dropdown-lists">
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map((option) => (
                      <span key={option.value} className="slide-left">
                        <input
                          style={{ display: multiSelectDisable ? 'none' : '' }}
                          type="checkbox"
                          checked={selectedValues.includes(option.value)}
                          onChange={() => handleCheckboxChange(option.value)}
                        />
                        <p role="presentation" onClick={() => handleCheckboxChange(option.value)}>
                          {option.label}
                        </p>
                      </span>
                    ))
                  ) : (
                    <p className="no-options">No options found</p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
MultiSelectDropdown.defaultProps = {
  title: '',
  placeholder: '',
  icon: null,
  search: false,
  multiSelectDisable: false,
};

export default MultiSelectDropdown;
