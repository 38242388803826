import config from '../../config/config';

import getRequest from './base/get';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the report list with pagination
   */
  fetchReports: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${config.reportApiUrl}api/super-admin/v1/charging-transaction`, {
      ...parameters,
    });
  },
  /**
   * Fetch the report details
   */
  fetchReportDetails: (): Promise<any> => {
    return getRequest(
      `${config.reportApiUrl}api/super-admin/v1/charging-transaction/charging-transaction-revenue-details`,
      {},
    );
  },
  /**
   * export the report list
   */
  exportReports: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${config.reportApiUrl}api/super-admin/v1/charging-transaction/export`, {
      ...parameters,
    });
  },
};
