// WebSocketContext.tsx
import React, { useContext, useEffect, useMemo } from 'react';

import config from '../../config/config';
import webSocketService from '../../websocket/wsService';

import WebSocketContext from './WebSocketContext';

/**
 * Web socket provider
 * @param param0
 * @returns
 */
export function WebSocketProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Connect to WebSocket
    const socketUrl = `${config.websocketUrl}?mode=guest&anyUser=superAdminCommands`;
    webSocketService.connect(socketUrl);

    return () => {
      // Disconnect WebSocket on cleanup
      webSocketService.disconnect();
    };
  }, []);

  // Use useMemo to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      webSocketService,
    }),
    [webSocketService],
  );

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
}

/**
 * Use websocket context
 * @returns
 */
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    console.warn('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
