/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OcppMessage, OcppMessageInitialState } from '../../interface/ocpp-message.interface';

// Initial state
const initialState: OcppMessageInitialState = {
  messages: [],
};

// Create the slice
export const ocppMessageSlice = createSlice({
  name: 'ocpp-message',
  initialState,
  reducers: {
    /**
     * Function to update to received message to existing message
     * @param state
     * @param action
     */
    pushReceivedMessage: (state: OcppMessageInitialState, action: PayloadAction<OcppMessage>) => {
      state.messages.push(action.payload);
    },
  },
});

// Export the actions
export const { pushReceivedMessage } = ocppMessageSlice.actions;

// Export the reducer
export default ocppMessageSlice.reducer;
