import { createContext } from 'react';

import webSocketService from '../../websocket/wsService';

// Define the context type
interface WebSocketContextType {
  webSocketService: typeof webSocketService; // Reference the existing webSocketService type
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export default WebSocketContext;
