import React from 'react';

import { useAppSelector } from '../../redux/hooks';

/**
 * Ocpp console component
 * @returns
 */
function OcppConsole() {
  const { messages } = useAppSelector((state) => state.ocppMessage);

  return (
    <div className="ocpp-console-wrap">
      <p>Ocpp message console</p>
      <div className="ocpp-console-message">
        {messages
          .slice()
          .reverse()
          .map((message) => (
            <div className="single-message-wrap">
              <h3>{message.type}: </h3>
              <p> {message.value}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default OcppConsole;
