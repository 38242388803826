import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Sidebar icons
import { ReactComponent as ConfigurationActiveIcon } from '../../../assets/icons/sidebar/ocpp-message/configuration-active.svg';
import { ReactComponent as ConfigurationIcon } from '../../../assets/icons/sidebar/ocpp-message/configuration.svg';
import { ReactComponent as ToggleClose } from '../../../assets/icons/toggle-close.svg';
import { ReactComponent as ToggleOpen } from '../../../assets/icons/toggle-open.svg';
// import { permissionCheck } from '../../../shared-functions/JWTDocodeFunction';

/**
 * Side bar function component
 */
function OCPPMessageSidebar() {
  const sidebarReference = useRef<HTMLDivElement>(null);
  const [expandSidebar, setExpandSidebar] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarMenu, setSideBarMenu] = useState([
    {
      id: 0,
      label: 'Get Configuration',
      url: '/ocpp-message/get-configuration',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 1,
      label: 'Change Configuration',
      url: '/ocpp-message/change-configuration',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 2,
      label: 'Clear Cache',
      url: '/ocpp-message/clear-cache',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 3,
      label: 'Remote Start Transaction',
      url: '/ocpp-message/remote-start-transaction',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: false,
    },
    {
      id: 4,
      label: 'Remote Stop Transaction',
      url: '/ocpp-message/remote-stop-transaction',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: false,
    },
    {
      id: 5,
      label: 'Reset',
      url: '/ocpp-message/reset',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 6,
      label: 'Get Diagnostics',
      url: '/ocpp-message/get-diagnostics',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 7,
      label: 'Unlock Connector',
      url: '/ocpp-message/unlock-connector',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 8,
      label: 'Reserve Now',
      url: '/ocpp-message/reserve-now',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 9,
      label: 'Cancel Reservation',
      url: '/ocpp-message/cancel-reservation',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 10,
      label: 'Get Local List Version',
      url: '/ocpp-message/get-local-list-version',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
    {
      id: 11,
      label: 'Trigger Message',
      url: '/ocpp-message/trigger-message',
      svgIcon: <ConfigurationIcon />,
      activeSvgIcon: <ConfigurationActiveIcon />,
      allow: true,
    },
  ]);

  /**
   * Handle the click menu function
   * @param url
   */
  const handleMenuClick = (url: string) => {
    setSideBarMenu((previous) =>
      previous.map((menu) => ({
        ...menu,
        isActive: menu.url === url,
      })),
    );
    navigate(url);
  };

  useEffect(() => {
    /**
     * Close popup
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarReference.current && !sidebarReference.current.contains(event.target as Node)) {
        setExpandSidebar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="sidebar-wrap">
      <div
        className={`sidebar-container ${expandSidebar ? 'expand' : 'close'}`}
        ref={sidebarReference}>
        <div
          className="toggle-button"
          role="presentation"
          onClick={() => setExpandSidebar(!expandSidebar)}>
          <span
            role="presentation"
            onClick={() => setExpandSidebar(true)}
            className={`toggle-icon ${expandSidebar ? 'disable' : 'active'}`}>
            <ToggleOpen />
          </span>
          <span
            role="presentation"
            onClick={() => setExpandSidebar(false)}
            className={`toggle-icon ${expandSidebar ? 'active' : 'disable'}`}>
            <ToggleClose />
          </span>
        </div>
        <div className="sidebar-menus">
          {sidebarMenu?.map(
            (menu) =>
              menu.allow && (
                <div
                  className={`sidebar-menu ${expandSidebar ? 'expand' : 'close'} ${location.pathname === menu.url ? 'active' : 'in-active'}`}
                  key={menu?.id}
                  role="presentation"
                  onClick={() => handleMenuClick(menu.url)}>
                  <div
                    className={`menu-wrap ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                    <div
                      className={`menu-icon-container ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                      <span>
                        {location.pathname === menu.url ? menu.activeSvgIcon : menu.svgIcon}
                      </span>
                    </div>
                    <span
                      className={`menu-label ${expandSidebar ? 'expand' : 'close'} ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                      {menu.label}
                    </span>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  );
}

export default OCPPMessageSidebar;
