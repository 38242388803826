import config from '../../config/config';

import getRequest from './base/get';

export default {
  /**
   * Fetch the charging station list with pagination
   */
  fetchChargingPoints: (): Promise<any> => {
    return getRequest(`${config.commonApiUrl}api/super-admin/v1/charging-point`);
  },
};
